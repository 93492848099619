<template>
  <section class="client-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12">
          <carousel
            :items="1"
            :nav="false"
            :autoplay="true"
            :dots="false"
            :loop="true"
            :margin="15"
            slideTransition="linear"
            :autoplayTimeout="4000"
            :autoplaySpeed="false"
            :smartSpeed="6000"
            :responsive="{
              0: { items: 2 },
              500: { items: 3 },
              600: { items: 4 },
              800: { items: 5 },
              1200: { items: 6 },
              1400: { items: 7 },
            }"
            class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
          >
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_1_V1.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_2_V1.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_3_V1.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_logo_sponsor_4_V1_v3.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_5_V1.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_6_V1.png"
                alt="client logo"
                class="client-img"
              />
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "SaintsSponsors",
  components: { carousel },
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
