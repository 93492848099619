var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"client-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[_c('carousel',{staticClass:"owl-theme clients-carousel dot-indicator owl-loaded owl-drag",attrs:{"items":1,"nav":false,"autoplay":true,"dots":false,"loop":true,"margin":15,"slideTransition":"linear","autoplayTimeout":4000,"autoplaySpeed":false,"smartSpeed":6000,"responsive":{
            0: { items: 2 },
            500: { items: 3 },
            600: { items: 4 },
            800: { items: 5 },
            1200: { items: 6 },
            1400: { items: 7 },
          }}},[_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_1_V1.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_2_V1.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_3_V1.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_logo_sponsor_4_V1_v3.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_5_V1.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/poloxjazz_logos/icon_logo_sponsor_6_V1.png","alt":"client logo"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }