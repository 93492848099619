<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_DiamondsInTheRivieraMayaCollection_V2.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                font-size: 2.3em;
                color: #000000;
              "
            >
              🎉 2025 Diamonds in the Riviera Maya: Cinco de Mayo in Mexico 🇲🇽
            </p>
          </div>
          <hr />
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                id="d3"
                class="mt-5"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.3em;
                  color: black;
                "
              >
                🌟 About the Experience <br />
                Join us for 2025 Diamonds in the Riviera Maya: Cinco de Mayo in
                Mexico — a signature experience that blends service,
                celebration, and culture in the stunning paradise of Playa del
                Carmen.
                <br />
                <br />
                This immersive getaway is designed for brothers and guests of
                Mexico Nupes. to enjoy a unique mix of fellowship, adventure,
                wellness, and nightlife, while honoring our commitment to
                brotherhood and service. From beach walks to catamaran cruises,
                fine dining, and a Cinco de Mayo celebration in Tulum, this is
                one international experience you don’t want to miss.
                <br /><br />
                📅 Dates: Thursday, May 1 – Monday, May 5, 2025<br />
                📍 Location: Playa Del Carmen, Mexico<br />
                🏨 Host Hotel: Aloft Playa Del Carmen<br />
                Calle 34 Nte Manzana 100 Lote 3, entre 10 Av norte y 20 Av.
                norte, Gonzalo Guerrero, 77710 Playa del Carmen, Q.R., Mexico
                <br /><br />
                🧳 Travel Itinerary Highlights<br />
                <br />
                🛬 Day 1: Thursday, May 1 — Arrival Day - Introduction to Los
                Nupes<br />
                Arrive in Cancun Airport (CUN) by 3:00 PM<br />
                Airport Transfer options
                <b>(**Transfer is Self-guided At Attendees Own Expense**)</b>
                from Cancun Airport to Playa Del Carmen:<br />
                - JF Riviera Transportation ($75 USD) – Contact Rasheed
                Dennis.<br />
                - ADO Charter Bus (255 MXN Pesos/$12.60 USD). Look for the ADO
                booth after customs. Taxi from Playa del Carmen bus station to
                the hotel is ~$10 USD<br />
                - Or hire a Taxi<br />

                <br />
                Check-in at Aloft Playa Del Carmen (Host Hotel)<br />
                Welcome Reception at Hospitality Suite (4:00 PM – 6:30 PM)<br />
                Welcome Happy Hour @ Juke Joint<br />

                <br />

                🌊 Day 2: Friday, May 2 — Day of Adventure<br />
                Breakfast
                <b
                  >(**This Breakfast is Self-guided At Attendees Own
                  Expense**)</b
                ><br />
                <b>“Brothers Only”</b> Health & Wellness Morning Beach Walk &
                Meditation (8:00 AM)<br />
                Krimson Katamaran Kruise <br />
                Open bar, buffet, snorkeling, transport, taxes & tips<br />
                Happy Hour @ Grand Hyatt<br />
                Dinner at Trujillos Cantina<br />
                After-party @ El Roof Bar<br />

                <br />
                🤝 Day 3: Saturday, May 3 — Day of Work, Service and Play <br />
                Mandatory In-person MAA Meeting + Group Photo <br />
                Service Project: Guide Right Activity - Mentoring Local
                Community <br />
                ATV Jungle Adventure with Ziplines, Cenote + Tequila Tasting
                <br />
                Signature All Inclusive All White Dinner Party/Black Dinner
                Party <br />
                3-course dinner, open bar, entertainment <br />
                Attire: All White <br />

                <br />
                ⛳ Day 4: Sunday, May 4 — Day of Brunching, Sipping and
                Learning<br />
                Morning Activities: <br />
                Golf Time at Cancun in Riviera Maya<br />
                Adios Amigos Brunch, Sip & Learn:<br />
                Topic: Real Estate in Riviera Maya <br />
                @ La Cocina, Grand Hyatt<br />

                <br />
                🎊 Day 5: Monday, May 5 — Cinco de Mayo, On the YO! And On Your
                Own Activity Options<br />
                Option 1: Tulum Beaches Day Trip; or<br />
                Option 2: Chichen Itza Ruins (7th Wonder of the World) Day Trip
                <br />
                Option 3: XCaret Mexican Amusement Park:Includes park's
                admission, more than 50 activities & access to the Xcaret México
                Espectacular show.<br />

                <br />

                <b
                  >(**This Cinco de Mayo Day is Self-guided At Attendees Own
                  Expense**)</b
                >
                <br />
                <br />
                Expect an unforgettable cultural celebration to cap off the
                trip! <br />
                <br />

                🏨 Accommodation<br />
                🏢 Hotel Name: Aloft Playa Del Carmen – The Host Hotel!<br />
                📍 Address: Playa Del Carmen, Mexico<br />
                📞 Phone Number: +52-984-147-6565<br />
                🗓️ Block Room Rate Dates: May 1st – May 6th, 2025<br />
                <!-- 🛏️ Reservation Dates: May 1st – May 5th, 2025<br /> -->

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_hotel_daimond_mexico_V1.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />

                <br />
                <br />FAQ’s:<br />
                Q: How do I make a hotel reservation since we can’t do it here
                on this site? <br />
                A: Hotel must be booked separately by the attendee. However, to
                get the $90/night block room rate, please click this
                <a href="https://forms.gle/D2qspq7TvCmxQN9o7" target="_blank">
                  link
                </a>
                to submit your Aloft Playa Del Carmen details. For any questions
                about accommodations, please email Brother Kyle “KB” Butts at
                GrandPi92@yahoo.com.

                <br /><br />

                ✈️ Transportation<br />
                Airlines Recommended: Delta, American, United, Southwest,
                Frontier.<br />
                Local Transportation Coordination: Taxi’s are plentiful, safe
                and affordable.
                <br />
                <br />

                🧳 Additional Info<br />

                🌡️ Weather in May: Highs of ~88°F / Lows ~78°F.<br />
                💱 Currency Exchange Rate: ~20 MXN Pesos = $1 USD.<br />
                🛂 Visa Requirements: None for U.S. citizens traveling to
                Mexico.<br />

                <br />
                🎒 Packing List Includes:<br />
                🩳 Beachwear and casual attire for daytime activities<br />
                👟 Activewear for excursions and fitness sessions<br />
                👔 Suit & Tie for Saturday’s In-Person Meeting<br />
                ⚪ All-white outfit for Saturday night dinner (Knite Klean)<br />
                📄 Passport & copies of travel documents<br />
                For Saturday Photo:<br />
                Charter Members: Cinco De Mayo Polo, Khaki Pants, White Tennis
                Shoes <br />
                Non-Charter Members: Red or White Nupe Polo, Khaki Pants, White
                Tennis Shoes <br />

                <br />

                We can't wait to celebrate brotherhood, culture, and joy with
                you in Mexico! 🎉🌴🇲🇽<br />
                <br />
                #LosNupes
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: black;
            "
          >
            01 <br />
            May
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-arrival-day-introduction-to-los-nupes"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Day 1: Arrival Day - Introduction to Los Nupes
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              🗓️ Thursday, May 01, 2025
              <br />
              ⏰ 3:00 PM - 11:59 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/diamonds-in-the-riviera-maya-arrival-day-introduction-to-los-nupes"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-arrival-day-introduction-to-los-nupes"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_IntroductionToLosNupes_V1.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: black;
            "
          >
            02 <br />
            May
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-day-of-adventure"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Day 2: Day of Adventure
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              🗓️ Friday, May 02, 2025
              <br />
              ⏰ 06:00 AM - 10:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/diamonds-in-the-riviera-maya-day-of-adventure"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-day-of-adventure"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_DayOfAdventure_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;

              color: black;
            "
          >
            03 <br />
            May
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-day-of-work-service-play"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Day 3: Day of Work, Service & Play
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              🗓️ Saturday, May 03, 2025
              <br />
              ⏰ 08:30 AM - 11:30 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/diamonds-in-the-riviera-maya-day-of-work-service-play"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-day-of-work-service-play"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_DayOfWorkServicePlay_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: black;
            "
          >
            04 <br />
            May
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="diamonds-in-the-riviera-maya-day-of-brunching-learning"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Day 4: Day of Brunching & Learning
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              🗓️ Sunday, May 04, 2025
              <br />
              ⏰ 07:00 AM - 11:30 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="diamonds-in-the-riviera-maya-day-of-brunching-learning"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="diamonds-in-the-riviera-maya-day-of-brunching-learning"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/DayOfBrunchingLearning_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: black;
            "
          >
            05 <br />
            May
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-day-trip-to-tulum"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Day 5: On the Yo! Day Trip to Tulum
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              🗓️ Monday, May 05, 2025
              <br />
              ⏰ 07:00 AM - 11:30 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/diamonds-in-the-riviera-maya-day-trip-to-tulum"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/diamonds-in-the-riviera-maya-day-trip-to-tulum"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_DayTripToTulum_V1.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />

      <div class="row w-100 m-0 promo-section MYLE-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2 class="text-center text-white">
                DOWNLOAD MYLE TODAY TO GET STARTED
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn google-play-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 
          <div
            class="row align-items-center justify-content-between test"
            style="margin: auto; margin-top: 20px"
          >
            <div class="col-lg-3 col-md-3">
              <img
                class="align-middle"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"
                style="width: 100%; max-width: 100%; height: auto"
              />
            </div>
            <div class="col-lg-9 col-md-9">
              <p
                class="mt-0 mb-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 24px;
                  background-color: #ac1e2e;
                  color: white;
                "
              >
                Get ready for The 86th Kappa Konclave with MYLE
              </p>
    
              <p
                class="mb-2 ml-4"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                • Find all of the “Konclave on the Bay” events in MYLE
                <br />
                • Buy your event tickets with MYLE
                <br />
                • Keep all of your digital tickets in MYLE for easy and fast
                check-in
                <br />
                • Help the local chapter earn referral points – use referral code
                “TampaAlumniChapter” during account set-up
                <br />
                • Receive important updates before and during the event
                <br />
                • Get around Tampa seamlessly using the MYLE app for transportation
                options including Waze/Apple Maps/Google Maps/Uber and Lyft –
                integrated into MYLE mobile.
                <br />
                • Find other fun things to do in Tampa or on the way!
                <br />
              </p>
            </div>
          </div> -->

      <!-- <div class="row">
                <div class="col-md-6 col-lg-6 align-items-center">
                  <a
                    href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                        alt="Saturday Event"
                        class="w-100 img-fluid"
                      />
                    </div>
        
                    <p
                      class="text-center lead-heading ml-3 mb-0"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      For The Kings | Juneteenth + Father's Day Brunch
                    </p>
                    <p
                      class="text-center lead-heading ml-3 mb-3"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 300;
                        font-size: 16px;
                      "
                    >
                      Sun, Jun 19, 12:00 PM
                    </p>
                  </a>
                </div>
              </div> -->
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../../views/commons/NavBar";
import SiteFooter from "../../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "Diamonds in the Riviera Maya: Cinco de Mayo in Mexico",

    meta: [
      {
        name: "description",
        content: "Diamonds in the Riviera Maya: Cinco de Mayo in Mexico",
      },

      {
        name: "keywords",
        content: "Diamonds in the Riviera Maya: Cinco de Mayo in Mexico",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/diamonds-in-the-riviera-maya-cinco-de-mayo-in-mexico",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
