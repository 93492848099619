<template>
  <div style="background-color: #110f0f">
    <nu-modal ref="termsModal" title="">
      <div class="container-fluid">
        <div class="row">
          <!-- Ticket Cart -->
          <div
            class="row align-items-center justify-content-between"
            style="margin: auto; width: 100% !important"
          >
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito';
                    font-size: 2.3em;
                    font-weight: 700;
                    color: #000000;
                  "
                >
                  THE GAME OF POLO
                </span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div style="height: auto; overflow: auto">
                <p
                  class="mb-1 mt-3"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.1em;
                    color: black;
                    width: 100%;
                  "
                >
                  <span class="bolded">
                    The sport of Kings bringing back ALL Black Polo Players!
                  </span>

                  <br /><br />

                  <span class="bolded"> Origins of Polo </span>
                  <br />
                  Polo is the oldest ball sport in the world dating back to 600
                  BC. Polo is derived from the “pulu”, the willow root from
                  which the polo balls were crafted in Tibet over 2000 years
                  ago.
                  <br />
                  The sport gradually spread through Asia to India, where it was
                  introduced to tea planters and British soldiers, who brought
                  the game to England. The first match was played in the UK in
                  1871 between the 9th lancers and 10th Hussars. One of the
                  players in this match was Captain Frank Henry who started the
                  Beaufort Polo Club in 1872.
                  <br />
                  <br />
                  <span class="bolded"> Polo for the Spectator </span>
                  <br />
                  What makes polo such a special spectator sport is that you
                  don’t have to be familiar with the rules, allowing you to take
                  in the thrill and excitement of one of the fastest team sports
                  in the world. The following information is designed to give
                  you an insight into the finer parts of the game of polo.
                  <br /><br />
                  <span class="bolded"> The Game </span>
                  <br />
                  The aim of the game is to score more goals than your opposing
                  team! Play starts initially, and after each goal, with an
                  umpire throwing the ball among the players in the middle of
                  the ground. Ends are changed after each goal – this has been
                  found to be the fairest way for teams not to be disadvantaged
                  by wind or slopes on the pitch! When the ball goes out of play
                  over the sides of the ground, teams line up side by side five
                  yards back and the ball is thrown in.
                  <br />
                  If the ball crosses the back line, being last touched by the
                  attacking team, the defending team takes a free hit from where
                  the ball crossed the line. Should the defending team hit the
                  ball over the back line a penalty is called and the attacking
                  team is given a free hit from the 60 yard line opposite where
                  the ball went out. There is no ‘corner’ or offside as in
                  football.
                  <br /><br />
                  <span class="bolded">The Ground </span>
                  <br />
                  The ground is 300 yards long (274m) long and 200m (182m) wide,
                  or if there are boards down the side (to help keep the ball in
                  play) then 160 yards 182 wide. The goal posts (collapsible on
                  severe impact for safety) are eight yards (7.3m) apart. There
                  is a line at the centre of the ground and penalty lines, 30,
                  40 and 60 yards from each back line. A polo ground is roughly
                  the size of 6 football pitches!
                  <br /><br />
                  <span class="bolded">Duration of Play</span>
                  <br />
                  The game is played over four, five or six periods of 7 minutes
                  each which are called by the Indian name “Chukka”. At the end
                  of the Chukka the first bell is rung, but play continues until
                  the ball goes out of play or the second bell is rung.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>

    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <!-- Ticket Cart -->
            <div class="col-md-12">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Purchase
                </h4>
              </div>
            </div>

            <div class="col-md-6 mt-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Full Name
              </span>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6 mt-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Email
              </span>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>

            <div class="col-md-6 mt-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Phone
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter phone number"
                @keypress="isNumber()"
                v-model="userContactPhone"
              />
              <span
                v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*please fill valid phone number</span
              >
            </div>

            <hr v-if="!isDonate" class="w-100 p-2" />
            <!-- Ticket Cart -->

            <!-- Ticket Options -->
            <div class="col-md-12" v-if="!isDonate && !isSponsor">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Ticket Options
                </h4>
              </div>
            </div>

            <div class="col-md-4" v-if="!isDonate && !isSponsor">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Ticket Type</label
              >
              <select
                @change="
                  () => {
                    redeemData = null;
                    redeempromo = '';
                    dayChange('daylist');
                  }
                "
                class="form-control"
                v-model="daylist"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, true, false)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3" v-if="!isDonate && !isSponsor">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >

              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist.length == 0 &&
                    initvalue[daylist.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option disabled v-if="initvalue[daylist.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div
              class="col-md-4 mt-4 text-center"
              v-if="!isDonate && !isSponsor"
            >
              <p
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Per Person:</b
                >
                ${{
                  !redeemData
                    ? ttypelist && ttypelist.price
                      ? ttypelist.price.toFixed(2)
                      : "0.00"
                    : redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >

                ${{
                  (
                    (ttypelist && ttypelist.price ? ttypelist.price || 0 : 0) *
                    (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (redeemData ? redeemData.price || 0 : 0) * (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-3"
              v-if="
                TicketsResult[selected] &&
                isDonate == false &&
                isSponsor == false &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              class="col-md-6 mt-3 text-center"
              v-if="
                TicketsResult[selected] &&
                isDonate == false &&
                isSponsor == false &&
                TicketsResult[selected].discount
              "
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Do you have a promo code?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />

              <button
                type="button"
                @click="redeem('daylist')"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-UNCFGala btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-3 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                isDonate == false &&
                isSponsor == false &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              v-if="!isDonate && !isSponsor"
              class="col-md-12 text-center my-4"
            >
              <input
                type="button"
                @click="addtocart('daylist')"
                class="btn solid-btn-UNCFGala m-auto mr-2"
                :disabled="!daylist || !tickcount"
                value="Add to cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr v-if="isDonate" class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Donation Options -->
            <div v-if="isDonate" class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Vendor Registration(s)
                </span>
              </div>
            </div>

            <div v-if="isDonate" class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Registration Type</label
              >
              <select
                @change="
                  () => {
                    redeemData = null;
                    redeempromo = '';
                    dayChange('daylist1');
                  }
                "
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, false, true)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div v-if="isDonate" class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Registration(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist1"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more vendor registration
                </option>

                <option disabled v-if="initvalue[daylist1.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div v-if="isDonate" class="col-md-4 mt-4 text-center">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Per Amount:</b
                >
                ${{
                  !redeemData
                    ? ttypelist1 && ttypelist1.price
                      ? ttypelist1.price.toFixed(2)
                      : "0.00"
                    : redeemData.price.toFixed(2)
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-3"
              v-if="
                TicketsResult[selected] &&
                isDonate == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              class="col-md-6 mt-3 text-center"
              v-if="
                TicketsResult[selected] &&
                isDonate == true &&
                TicketsResult[selected].discount
              "
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Do you have a promo code?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />

              <button
                type="button"
                @click="redeem('daylist1')"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-UNCFGala btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-3 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                isDonate == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div v-if="isDonate" class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-UNCFGala m-auto mr-2"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <!-- Donation Options -->
            <div v-if="isSponsor" class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Sponsorship Registration(s)
                </span>
              </div>
            </div>

            <div v-if="isSponsor" class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Sponsorship Type</label
              >
              <select
                @change="
                  () => {
                    redeemData = null;
                    redeempromo = '';
                    dayChange('daylist1');
                  }
                "
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, false, false)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div v-if="isSponsor" class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Sponsorship(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist1"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more sponsorship registration
                </option>

                <option disabled v-if="initvalue[daylist1.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div v-if="isSponsor" class="col-md-4 mt-4 text-center">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Per Amount:</b
                >
                ${{
                  !redeemData
                    ? ttypelist1 && ttypelist1.price
                      ? ttypelist1.price.toFixed(2)
                      : "0.00"
                    : redeemData.price.toFixed(2)
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-3"
              v-if="
                TicketsResult[selected] &&
                isSponsor == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              class="col-md-3 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                isSponsor == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div v-if="isSponsor" class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-UNCFGala m-auto mr-2"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Cart details -->
            <div class="col-md-12">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Cart Details
                </h4>
              </div>
            </div>

            <div class="col-md-12">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Selected item(s)
                    </th>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                        text-align: center;
                      "
                    >
                      Quantity
                    </th>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                        text-align: center;
                      "
                    >
                      Amount
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in selectedCarts" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.ticketName }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.tttcount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>${{ (r.tttcount * r.price).toFixed(2) }}</p>
                    </td>
                    <td style="width: 10%; text-align: right; cursor: pointer">
                      <p>
                        <span
                          @click="deleteRec(r, i)"
                          style="
                            color: #d90606;
                            display: block;
                            font-weight: bold;
                            font-family: 'Quicksand';
                          "
                        >
                          X</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 20px;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.1em;
                        color: grey;
                      "
                    >
                      <p class="text-center">
                        No Ticket(s) / Vendor(s) / Sponsorship(s)
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Cart details -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>

            <hr
              class="w-100 p-2"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            />
            <div
              class="row"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6 mb-2">
                <label class="">Name of Business/Individual</label>
                <input
                  type="text"
                  v-model="nameOfBusiness"
                  class="form-control"
                  placeholder="Name of Business/Individual"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Address</label>
                <input
                  type="text"
                  v-model="address"
                  class="form-control"
                  placeholder="Address"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">City, State, Zip</label>
                <input
                  type="text"
                  v-model="cityStateZip"
                  class="form-control"
                  placeholder="City, State, Zip"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Contact Person</label>
                <input
                  type="text"
                  v-model="contactPerson"
                  class="form-control"
                  placeholder="Contact Person"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="">Contact Number</label>
                <input
                  type="text"
                  v-model="telephoneNumber"
                  class="form-control"
                  placeholder="Contact Number"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Email Address</label>
                <input
                  type="text"
                  v-model="emailAddress"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: grey;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynowcustom()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  !invaliduphone ||
                  isSubmitted ||
                  !selectedCarts.length ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-UNCFGala m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->
      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_PoloxJazz_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #eae492; border-top: 1px solid white"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Cinzel Decorative', sans-serif;
                  font-weight: 700;
                  font-size: 2.3em;
                  color: #110f0f;
                "
              >
                Grandiosity Events 9th Annual Celebrity PoloxJazz
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #110f0f;
                "
              >
                🗓️ Sunday, May 25, 2025
                <br />
                ⏰ 12:00 PM - 09:00 PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #110f0f;
                "
              >
                <a
                  href="https://maps.app.goo.gl/yrQJNnG1rY35wB8z6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #110f0f"
                >
                  📍 Baltimore Peninsula
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-0 p-3" style="background: #000000">
        <div class="text-center">
          <a
            href="#tickets"
            class="btn solid-btn-PoloxJazz ml-3 mr-3 mb-1 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            rel="noopener noreferrer"
            >Get Tickets</a
          >

          <a
            @click="openTermsModel()"
            class="btn solid-btn-PoloxJazz ml-3 mr-3 mb-1 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            rel="noopener noreferrer"
            >The Game of Polo</a
          >
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content mt-2">
              <p
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.4em;
                  color: #ffffff;
                "
              >
                Welcome to Grandiosity Events 9th annual celebrity PoloxJazz 🐎
              </p>

              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.4em;
                  color: #ffffff;
                "
              >
                Sunday May 25th hosted by Celebrity Tionne #Tboz Watkins from
                the legendary all girl group #TLC to benefit
                #BALTIMOREHEALTHYSTART.ORG!

                <br /><br />
                Ooh la la! 🌼 🌸 🌻 🌹 🏵 🌼 🌸 🌻 🌹

                <br /><br />
                New venue: #BaltimorePeninsula overlooking Baltimore Inner
                Harbor! 🚢

                <br /><br />
                Baltimore Healthy Start, Inc. is committed to reducing infant
                and maternal mortality and morbidity by utilizing a Life Course
                Perspective for improving the health and well-being of women and
                their families through the provision of comprehensive,
                supportive services offered in the communities where they live.
                501(c)3
                <br /><br />
                Baltimore Healthy Start assists approximately 1000 women
                annually.

                <br /><br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                12p Gates open

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                1p Red Carpet interviews

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                3p Match starts

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                4p Half-time/Divot stomping

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                5p Trophy Presentation by sponsor/Remarkable on stage

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                6-9 After Party with DJ Vytas Reid Emmy Award Winning Chief
                Meterologist CBS12 West Palm Beach

                <br />

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                May bring your own posh picnic set up

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                General Admission • Bring your own chair

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                No outside alcohol permitted

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                Food Trucks • Vendors • Cash bar on premises

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                Paid parking on premises

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                Host Hotel • The Luxurious Pendry Waterfront

                <br />

                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_horse.png"
                  style="width: 3%"
                />
                Ride share highly recommended • Drink Responsibly

                <br /><br />

                <span style="font-weight: 700; font-size: 1.5em"
                  >🎟 General Admission</span
                >
                <br />
                Price: $175.00 + $13.38 (Fees) = $188.38<br />
                Includes: Access to event <br />
                ■ May bring your own posh picnic set up [optional] 🧺 <br />
                ■ Cash bar 🍹 and food trucks 🍔 on premises<br />
                🚫 No outside alcohol permitted<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🥂 VIP Individual Ticket </span
                ><br />
                Price: $500.00 + $35.17 (Fees) = $535.17<br />
                Includes: Access to VIP Area for 1 person:<br />
                ■ Guaranteed seating 🎟<br />
                ■ LaGrandeCaviar Grand LUX Caviar Bar 🐟<br />
                ■ Cuisine 🍽 <br />
                ■ Cocktails 🍸 <br />
                ■ Cupcakes 🧁<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em">🛍 Vendors</span
                ><br />

                Price: $1500.00 + $102.25 (Fees) = $1602.25<br />
                💸 Use the code vendor for a 50% discount – limited time
                offer!<br />
                All vendors must have Certificate of Insurance (COI)<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🛍 Polo x Jazz Vendor</span
                ><br />
                Price: $1500.00 + $102.25 (Fees) = $1602.25<br />
                Includes: Brand your biz in front of an upscale audience of
                1000+ attendees! 💼✨<br />
                Custom flyer shared on social media blasts 📱📢<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🪑 Lawn Table Seats 10 Guests</span
                ><br />
                Price: $2000.00 + $135.78 (Fees) = $2135.78<br />
                Includes: Seats 10 guests<br />
                ■ Optional posh picnic & tablescape 🧺 🌸<br />
                ■ Cash bar by Pour Decizion 🍸<br />
                ■ Food trucks and vendors 🍱 🚚<br />
                🚫 No outside alcohol permitted<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >💎 VIP Table Seats 10 Guests</span
                ><br />
                Price: $5000.00 + $337.00 (Fees) = $5337.00<br />
                Includes: Prime VIP seating for 10<br />
                ■ LaGrandeCaviar Grand LUX Caviar Bar 🐟<br />
                ■ Cuisine 🍽 ■ Cocktails 🍸 ■ Cupcakes 🧁<br />
                ■ Table signage with brand logo 🖼<br />
                📧 Email guest list:
                <a href="mailto:hello@grandiosityevents.com">
                  Hello@GrandiosityEvents.com</a
                ><br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🚽 Lux Latrine Sponsor</span
                ><br />
                Price: $8000.00 + $538.22 (Fees) = $8538.22<br />
                Includes: Sponsor premium restrooms<br />
                ■ Signage = guaranteed visibility 🚻🔥<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🏇🏿 Black Polo Team Sponsor</span
                ><br />
                Price: $10000.00 + $672.37 (Fees) = $10672.37<br />
                Includes:<br />
                ■ Sponsor all-Black polo team<br />
                ■ Logo on jerseys 👕<br />
                ■ Trophy presentation 🏆<br />
                ■ Step & repeat + media mentions 📸📺📻<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🍾 LaGrandeCaviar Bar Sponsor</span
                ><br />
                Price: $10000.00 + $672.37 (Fees) = $10672.37<br />
                Includes:<br />
                ■ Logo on linen at VIP caviar bar 🐟<br />
                ■ Custom flyer, social blasts, media mentions 📰📲<br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🏆 Polo x Jazz GOLD Corporate Sponsor</span
                ><br />
                Price: $15000.00 + $1007.73 (Fees) = $16007.73<br />
                Includes:<br />
                ■ 3 VIP tables (30 guests) 👥<br />
                ■ Logo on step & repeat 📸<br />
                ■ Custom flyer + social blasts<br />
                ■ Speaking opportunity 🎤<br />
                📧 Contact:
                <a href="mailto:hello@grandiosityevents.com">
                  Hello@GrandiosityEvents.com</a
                ><br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >🥇 Polo x Jazz PLATINUM SPONSOR</span
                ><br />
                Price: $50000.00 + $3355.29 (Fees) = $53355.29<br />
                Includes:<br />
                ■ 5 VIP tables (50 guests) 🎉<br />
                ■ Logo, media, national PR 📰<br />
                ■ Custom flyer, social blasts 🚀<br />
                ■ Custom activations 🎨<br />
                📧 Contact:
                <a href="mailto:hello@grandiosityevents.com">
                  Hello@GrandiosityEvents.com</a
                ><br />
                <br />
                <br />
                <span style="font-weight: 700; font-size: 1.5em"
                  >👑 Polo x Jazz PRESENTING TITLE SPONSOR</span
                ><br />
                Price: $100000.00 + $6708.94 (Fees) = $106708.94<br />
                Includes:<br />
                ■ “Presented by” title everywhere 🌟<br />
                ■ Total brand takeover 🔥<br />
                ■ Custom activations 🎯<br />
                📧 Contact:
                <a href="mailto:hello@grandiosityevents.com">
                  Hello@GrandiosityEvents.com</a
                >

                <br /><br />

                Refund Policy<br />
                No Refunds<br />
                <br />
                Inquiries:
                <a href="mailto:hello@grandiosityevents.com">
                  Hello@GrandiosityEvents.com</a
                >

                <br />
              </p>
            </div>

            <hr style="background-color: #5b5b5b" />

            <br />

            <div class="row mt-5">
              <div
                class="col-md-5 col-md-5"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.3em;
                    color: #ffffff;
                  "
                >
                  <span
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-weight: 600;
                    "
                    >Grand greetings LUX AUTO OWNERS attending #PoloxJazz this
                    year!</span
                  >

                  <br />
                  <br />
                  Parking is controlled by another entity. Therefore all LUX
                  AUTO OWNERS displaying their cars on PoloxJazz GRAND LUX AUTO
                  ROW will need credentials displayed on their windows in
                  advance.
                  <br />
                  Email for your credentials<br />
                  <a href="mailto:hello@grandiosityevents.com">
                    Hello@GrandiosityEvents.com</a
                  >
                </p>
              </div>

              <div class="col-md-7 col-md-7">
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_poloxjazz_cars_V1.jpg"
                    class="w-100 img-fluid"
                    style="border-radius: 5%"
                  />
                </div>
              </div>
            </div>

            <div class="container text-center mt-5" style="width: 35%">
              <div class="player-container">
                <vue-core-video-player
                  :volume="1"
                  @autoplay="false"
                  @loop="false"
                  title=""
                  cover="https://grandiosityevents.com/wp-content/uploads/2025/03/carlito.webp"
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/PoloxJazz_recap.mp4"
                ></vue-core-video-player>
              </div>
            </div>

            <div class="container text-center mt-2 mb-5" style="width: 52%">
              <span
                style="
                  font-family: 'Cinzel Decorative', sans-serif;
                  font-weight: 600;
                  font-size: 1.4em;
                  color: white;
                "
                >World Famous Arturo Fuente Cigars owner Carlito Fuente Jr check
                presentation to Black Mental Health Alliance
              </span>
              <br /><br />
              <br /><br />
            </div>

            <div class="row mt-5">
              <div
                class="col-md-6 col-md-6 text-center"
                style="font-family: 'Montserrat', sans-serif"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_BALTIMORE_copia.png"
                  class="w-75 img-fluid text-center"
                  style="border-radius: 5%"
                />

                <p
                  class=""
                  style="
                    font-family: 'Cinzel Decorative', sans-serif;
                    font-size: 1.3em;
                    color: #ffffff;
                    font-weight: 600;
                  "
                >
                  To benefit
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Cinzel Decorative', sans-serif;
                    font-size: 1.1em;
                    color: #ffffff;
                    text-align: left;
                  "
                >
                  Baltimore Healthy Start, Inc. is committed to reducing infant
                  and maternal mortality and morbidity by utilizing a Life
                  Course Perspective for improving the health and well-being of
                  women and their families through the provision of
                  comprehensive, supportive services offered in the communities
                  where they live.
                </p>
              </div>

              <div class="col-md-6 col-md-6">
                <div class="text-center">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Relleno_generativo.png"
                    class="img-fluid text-center"
                    style="border-radius: 5%; width: 65%"
                  />

                  <p
                    class="mt-0 mb-0"
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 1.1em;
                      color: #ffffff;
                      font-weight: 400;
                    "
                  >
                    HOSTED BY:
                  </p>

                  <p
                    class="mt-0 mb-0"
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 2em;
                      color: #eae492;
                    "
                  >
                    Tionne Tboz Watkins
                  </p>

                  <p
                    class="mt-0 mb-0"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1em;
                      color: #ffffff;
                    "
                  >
                    From the legendary all girl group TLC!
                  </p>
                </div>
              </div>
            </div>

            <hr style="background-color: #5b5b5b" />

            <div class="row text-center mt-5" id="tickets">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class="text-center"
                  style="
                    font-family: 'Cinzel Decorative', sans-serif;
                    font-size: 1.6em;
                    font-weight: 700;
                    color: #eae492;
                  "
                >
                  <span
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 1.6em;
                    "
                  >
                    TICKET OPTIONS
                  </span>
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  <button
                    class="btn solid-btn-PoloxJazz text-center shimmer"
                    @click="openmodelmyle(false, false)"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    Get Tickets
                  </button>
                </p>
              </div>
            </div>

            <hr />
            <br />

            <div class="row text-center">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    font-weight: 700;
                    color: #eae492;
                  "
                >
                  <span
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 1.6em;
                    "
                  >
                    VENDOR REGISTRATION
                  </span>
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  <button
                    class="btn solid-btn-PoloxJazz text-center shimmer"
                    @click="openmodelmyle(true, false)"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    Register
                  </button>
                </p>
              </div>
              <br />
            </div>

            <div class="row mt-5">
              <div class="col-md-3 col-md-3 text-center"></div>
              <div
                class="col-md-6 col-md-6 text-center"
                style="font-family: 'Montserrat', sans-serif"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Maritha_Waller_Gay.png"
                  class="w-100 img-fluid text-center mt-0 mb-0"
                  style="border-radius: 5%"
                />

                <p
                  class="mt-0 mb-0"
                  style="
                    font-family: 'Cinzel Decorative', sans-serif;
                    font-size: 1.4em;
                    color: #ffffff;
                    font-weight: 600;
                  "
                >
                  Welcome Mistress of Ceremonies
                </p>

                <p
                  class="mt-0 mb-0"
                  style="
                    font-family: 'Cinzel Decorative', sans-serif;
                    font-size: 2.2em;
                    color: #eae492;
                  "
                >
                  Maritha Waller Gay
                </p>
              </div>
              <div class="col-md-3 col-md-3 text-center"></div>
            </div>

            <div class="row text-center mt-5">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <div class="text-center">
                  <div class="text-center">
                    <h3
                      class="mb-0 w-auto d-inline-block"
                      style="
                        font-family: 'Cinzel Decorative', sans-serif;
                        font-size: 1.6em;
                        color: #ffffff;
                        font-weight: 700;
                      "
                    >
                      Sponsors
                    </h3>
                  </div>

                  <PoloxJazzSponsors />
                </div>

                <p
                  class="text-center mt-0 mb-4"
                  style="
                    font-family: 'Cinzel Decorative', sans-serif;
                    font-size: 1.6em;
                    color: #ffffff;
                    font-weight: 700;
                  "
                >
                  Sponsorship opportunites available now:
                </p>

                <p
                  class="mt-0"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #ffffff;
                  "
                >
                  <button
                    class="btn solid-btn-PoloxJazz text-center shimmer"
                    @click="openmodelmyle(false, true)"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    Become Sponsor
                  </button>
                </p>
              </div>
              <br />
            </div>

            <hr style="background-color: #5b5b5b" />

            <div
              class="row align-items-center justify-content-between test"
              style="margin: auto; margin-top: 20px"
            >
              <div class="col-md-12">
                <div class="row justify-content-center text-center">
                  <div
                    class="col-12 col-md-12 justify-content-center d-flex align-items-center"
                  >
                    <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_hotel_sagamore_V1.png"
                        alt="Slow Jam Social Event"
                        class="w-75 img-fluid"
                      />
                    </div>
                  </div>
                </div>

                <div class="download-content">
                  <p
                    class="text-center mb-3"
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 2.3em;
                      font-weight: 700;
                      color: #ffffff;
                    "
                  >
                    Sagamore Pendry
                  </p>

                  <p
                    class="text-center"
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 1.2em;
                      color: #ffffff;
                    "
                  >
                    Waterfront Hotel Resort • Sponsor and Host Hotel special
                    rates:
                  </p>
                </div>
              </div>

              <div class="row w-100">
                <div class="col-lg-12 col-md-12">
                  <div class="section-heading mb-5">
                    <div class="download-btn mt-4 text-center">
                      <a
                        href="https://book.passkey.com/event/50993928/owner/49658955/home"
                        class="btn solid-btn-PoloxJazz"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          margin: auto;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1em;
                          font-weight: bold;
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        BOOK HOTEL</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr style="background-color: #5b5b5b" />

            <div
              class="row align-items-center justify-content-between test"
              style="margin: auto; margin-top: 20px"
            >
              <div class="col-md-12">
                <div class="row justify-content-center text-center">
                  <p
                    class="text-center mb-3"
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 2.3em;
                      font-weight: 700;
                      color: #ffffff;
                    "
                  >
                    Location
                  </p>

                  <div
                    class="col-12 col-md-12 justify-content-center d-flex align-items-center"
                  >
                    <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/image_map_poloxjazz.png"
                        alt="Slow Jam Social Event"
                        class="img-fluid"
                        style="border-radius: 3%; width: 75%"
                      />
                    </div>
                  </div>
                </div>

                <div class="download-content text-center">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Peninsula.png"
                    alt="Slow Jam Social Event"
                    class="img-fluid"
                    style="border-radius: 5%; width: 10%"
                  />
                  <br />
                  <p
                    class="text-center"
                    style="
                      font-family: 'Cinzel Decorative', sans-serif;
                      font-size: 1.4em;
                      font-weight: 700;
                      color: #ffffff;
                    "
                  >
                    Baltimore Peninsula 101 W Dickman St, Baltimore, MD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import PoloxJazzSponsors from "../../views/commons/PoloxJazzSponsors";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../components/confirmationPopup";
import VueElementLoading from "vue-element-loading";
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      cntholder: {},
      isDonate: false,
      isSponsor: false,
      thermoResult: 0,
      options: {
        text: {
          color: "black",
          fontSize: 14,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 16,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 600,
          width: 140,
        },
      },

      selectedCarts: [],
      ttypelist: null,
      ttypelist1: null,
      nameOfBusiness: "",
      address: "",
      cityStateZip: "",
      contactPerson: "",
      telephoneNumber: "",
      emailAddress: "",
      attendingGala: "Yes",
      participateInVendor: "No",
      names: {},
      showsuccess: false,
      userContactPhone: "",
      vchange: true,
      validSection: true,
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {},
      section2: [
        "tk_xOSE1eC9qA32mK9",
        "tk_rN6Ulfjw7kR6bs3",
        "tk_Th3OQmFsgiGHdyl",
        "tk_dOoR4tK1mdzWXz9",
        "tk_n86GjBb59mvADgM",
        "tk_YUjIwRpufa2Sw64",
      ],

      donations: [
        "tk_Qq7Cda7DwDHqwlj",
        "tk_hrdiKpQQpGEs2nD",

        "tk_xOSE1eC9qA32mK9",
        "tk_rN6Ulfjw7kR6bs3",
        "tk_Th3OQmFsgiGHdyl",
        "tk_dOoR4tK1mdzWXz9",
        "tk_n86GjBb59mvADgM",
        "tk_YUjIwRpufa2Sw64",
      ],
      pricemap: {
        tk_8Et1PLZtWrgBhJP: "price_1R9EYxD0vTZ4QB9c9I8o38UO",
        tk_djH8J171oaZeyTo: "price_1R9EYxD0vTZ4QB9cvde6gXYu",
        tk_P2gNl1tnXsasFZy: "price_1R9EYzD0vTZ4QB9caX0OWpoK",
        tk_doJMBIVr7LwOjkG: "price_1R9EYzD0vTZ4QB9cteoGIhCo",

        tk_Qq7Cda7DwDHqwlj: "price_1R9EYyD0vTZ4QB9c86iSTdIW",
        tk_hrdiKpQQpGEs2nD: "price_1R9EYyD0vTZ4QB9c50smRsEq",

        tk_xOSE1eC9qA32mK9: "price_1R9EZ0D0vTZ4QB9czch6KeVb",
        tk_rN6Ulfjw7kR6bs3: "price_1R9EZ0D0vTZ4QB9cxcqLgmia",
        tk_Th3OQmFsgiGHdyl: "price_1R9EZ1D0vTZ4QB9crSDoy8OB",
        tk_dOoR4tK1mdzWXz9: "price_1R9EZ1D0vTZ4QB9cnvkGddDi",
        tk_n86GjBb59mvADgM: "price_1R9EZ1D0vTZ4QB9cNWPcEJ9g",
        tk_YUjIwRpufa2Sw64: "price_1RA9feD0vTZ4QB9cPxLvfYBV",
      },

      daylist: {},
      daylist1: {},
      completeList: [],
      initvalue: {},

      section1: [
        "tk_8Et1PLZtWrgBhJP",
        "tk_djH8J171oaZeyTo",
        "tk_P2gNl1tnXsasFZy",
        "tk_doJMBIVr7LwOjkG",

        "tk_Qq7Cda7DwDHqwlj",
        "tk_hrdiKpQQpGEs2nD",

        "tk_xOSE1eC9qA32mK9",
        "tk_rN6Ulfjw7kR6bs3",
        "tk_Th3OQmFsgiGHdyl",
        "tk_dOoR4tK1mdzWXz9",
        "tk_n86GjBb59mvADgM",
        "tk_YUjIwRpufa2Sw64",
      ],

      redeempromo: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_8Et1PLZtWrgBhJP: {
          messages: [],
        },

        tk_djH8J171oaZeyTo: {
          messages: [],
        },

        tk_P2gNl1tnXsasFZy: {
          messages: [],
        },

        tk_doJMBIVr7LwOjkG: {
          messages: [],
        },

        tk_Qq7Cda7DwDHqwlj: {
          messages: [],
        },

        tk_hrdiKpQQpGEs2nD: {
          messages: [],
        },

        tk_xOSE1eC9qA32mK9: {
          messages: [],
        },
        tk_rN6Ulfjw7kR6bs3: {
          messages: [],
        },
        tk_Th3OQmFsgiGHdyl: {
          messages: [],
        },
        tk_dOoR4tK1mdzWXz9: {
          messages: [],
        },
        tk_n86GjBb59mvADgM: {
          messages: [],
        },
        tk_YUjIwRpufa2Sw64: {
          messages: [],
        },
      },

      priceIds: {
        tk_8Et1PLZtWrgBhJP: "price_1R9EYxD0vTZ4QB9c9I8o38UO",
        tk_djH8J171oaZeyTo: "price_1R9EYxD0vTZ4QB9cvde6gXYu",
        tk_P2gNl1tnXsasFZy: "price_1R9EYzD0vTZ4QB9caX0OWpoK",
        tk_doJMBIVr7LwOjkG: "price_1R9EYzD0vTZ4QB9cteoGIhCo",

        tk_Qq7Cda7DwDHqwlj: "price_1R9EYyD0vTZ4QB9c86iSTdIW",
        tk_hrdiKpQQpGEs2nD: "price_1R9EYyD0vTZ4QB9c50smRsEq",

        tk_xOSE1eC9qA32mK9: "price_1R9EZ0D0vTZ4QB9czch6KeVb",
        tk_rN6Ulfjw7kR6bs3: "price_1R9EZ0D0vTZ4QB9cxcqLgmia",
        tk_Th3OQmFsgiGHdyl: "price_1R9EZ1D0vTZ4QB9crSDoy8OB",
        tk_dOoR4tK1mdzWXz9: "price_1R9EZ1D0vTZ4QB9cnvkGddDi",
        tk_n86GjBb59mvADgM: "price_1R9EZ1D0vTZ4QB9cNWPcEJ9g",
        tk_YUjIwRpufa2Sw64: "price_1RA9feD0vTZ4QB9cPxLvfYBV",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      userSchool: "",
      tickcount: 1,
      tickcount1: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      tickcountlist1: [],

      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
    };
  },
  components: {
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
    NavBar,
    SiteFooter,
    PoloxJazzSponsors,
  },
  methods: {
    setValues(c, t) {
      this.cntholder[this[t].ticketCode] = c;
    },
    openTermsModel: function () {
      this.$refs.termsModal.open();
    },
    fetchRouteTickets(list, isRoute, isDonation) {
      const routes = [
        "tk_8Et1PLZtWrgBhJP",
        "tk_djH8J171oaZeyTo",
        "tk_P2gNl1tnXsasFZy",
        "tk_doJMBIVr7LwOjkG",
      ];
      const donationn = ["tk_Qq7Cda7DwDHqwlj", "tk_hrdiKpQQpGEs2nD"];
      const sponsors = [
        "tk_xOSE1eC9qA32mK9",
        "tk_rN6Ulfjw7kR6bs3",
        "tk_Th3OQmFsgiGHdyl",
        "tk_dOoR4tK1mdzWXz9",
        "tk_n86GjBb59mvADgM",
        "tk_YUjIwRpufa2Sw64",
      ];

      if (isRoute) {
        return list.filter((r) => routes.includes(r.ticketCode));
      }

      if (isDonation) {
        return list.filter((r) => donationn.includes(r.ticketCode));
      }

      return list.filter((r) => sponsors.includes(r.ticketCode));
    },

    fetchStatus() {
      const req = "https://myle-19701.uc.r.appspot.com/api/event_total_sales";
      const self = this;
      self.thermoResult = 0;
      axios
        .post(req, {
          eventId: "qNHysbZJtltXll",
          userId: "",
        })
        .then((res) => {
          if (res && res.data && res.data.Result) {
            self.thermoResult =
              res.data.Result.totalSales > 0
                ? res.data.Result.totalSales / 1000
                : 0;
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    addtocart(t) {
      const rec = { ...this[t] };
      if (t == "daylist" && this.tickcount < 1) {
        return;
      }
      if (t == "daylist1" && this.tickcount1 < 1) {
        return;
      }
      if (rec && t == "daylist") {
        for (let c = 0; c < this.tickcount; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        console.log("dsds", this.cntholder);
        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount,
          t
        );
      }

      if (rec && t == "daylist1") {
        for (let c = 0; c < this.tickcount1; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount1,
          t
        );
      }
      console.log(this.redeemData, this.selectedCarts);
      this.selectedCarts = this.selectedCarts.map((r) => {
        if (this.redeemData && this.redeemData.ticketCode === r.ticketCode) {
          r.priceId = this.redeemData.discountedPriceId;
          this.priceIds[r.ticketCode] = this.redeemData.discountedPriceId;
          r.price = this.redeemData.price;
        }

        return r;
      });

      this.dayChange(t);
    },
    dayChange(t) {
      if (t == "daylist") {
        this.tickcount = 1;
        this.tickcountlist = [];
      } else {
        this.tickcount1 = 1;
        this.tickcountlist1 = [];
      }

      //  this[t].price = 100;
      const w = this[t];
      for (
        let k = 0;
        k <
        (w.availableTickets > w.allowedPerRequest
          ? w.allowedPerRequest
          : w.availableTickets);
        k++
      ) {
        if (!this.donations.includes(w.ticketCode)) {
          this.tickcountlist.push(k + 1);
        } else if (this.donations.includes(w.ticketCode)) {
          this.tickcountlist1.push(k + 1);
        }
      }
      if (t === "daylist") {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist.length;
          this.tickcountlist = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist.push(k1 + 1);
          }
        }

        if (this.tickcountlist.length == 0) {
          this.tickcount = 0;
        }
      } else {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist1.length;
          this.tickcountlist1 = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist1.push(k1 + 1);
          }
        }

        if (this.tickcountlist1.length == 0) {
          this.tickcount1 = 0;
        }
      }

      if (t == "daylist") {
        this.ttypelist = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist.length;
        }
      } else {
        this.ttypelist1 = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist1.length;
        }
      }
    },

    deleteRec(rec, i) {
      console.log(i);
      this.selectedCarts = this.selectedCarts.filter((x, it) => it != i);
      this.ttypelist = this.TicketsResult[0];
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },

    openmodelmyle(isDonate, isSponsor) {
      this.isDonate = isDonate;
      this.isSponsor = isSponsor;
      this.validSection = true;
      this.nameOfBusiness = "";
      this.attendingGala = "Yes";
      this.participateInVendor = "No";
      this.address = "";
      this.cityStateZip = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.fullname = "";
      this.email = "";
      this.userSchool = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.userContactPhone = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.names = {};
      this.ttypelist = this.TicketsResult[0];
      this.ttypelist = null;
      this.ttypelist1 = null;
      this.daylist = {};
      this.daylist1 = {};
      this.tickcount = 1;
      this.tickcount1 = 1;
      this.selectedCarts = [];
      this.tickcountlist = [];
      this.tickcountlist1 = [];
      this.initvalue = {};
      this.dayChange("daylist");
      this.dayChange("daylist1");
      this.redeemData = null;
      this.$refs.modal.open();
      this.selected = 0;
      const idx = 0;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynowcustom: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadData = {
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        userId: "",
        eventId: this.TicketsResult[0].eventId,
        origin: "WEB",
        tickets: [],
      };

      const payloaddata = {
        priceId: this.redeemPriceId,
        eventId: this.TicketsResult[0].eventId,
        ticketId: this.selectedCarts[0].ticketCode,
        totalTickets: this.selectedCarts.length,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {},
        extraInfo: {
          phone: this.userContactPhone,
        },
      };

      this.selectedCarts.forEach((re) => {
        payloadData.tickets.push({
          priceId: this.priceIds[re.ticketCode],
          ticketId: re.ticketCode,
          totalTickets: re.tttcount,
          additionalInfo: {},
          extraInfo: {
            phone: this.userContactPhone,
            business: this.nameOfBusiness,
            attendingGala: this.attendingGala,
            participateInVendor: this.participateInVendor,
          },
        });
      });

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "request_cart_tickets_v3"
              : "requested_free_tickets"),
          (this.TicketsResult[vm.selected].ticketType != "FREE" &&
            !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
            ? payloadData
            : payloaddata
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (re.data && re.data.Result) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.isSubmitted = false;
            vm.$refs.modal.close();
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    redeem: function (tt) {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this[tt].eventId,
          ticketId: this[tt].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemData = re.data.Result;
            this.selectedCarts = this.selectedCarts.map((r) => {
              if (
                this.redeemData &&
                this.redeemData.ticketCode === r.ticketCode
              ) {
                r.priceId = vm.redeemData.discountedPriceId;
                this.priceIds[r.ticketCode] = vm.redeemData.discountedPriceId;
                r.price = vm.redeemData.price;
              }

              return r;
            });
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "qNHysbZJtltXll",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          if (ticketsResult && ticketsResult.data.Result) {
            vm.priceedTicket = (ticketsResult.data.Result.filter(
              (r) => r.ticketCode === "tk_abUzszRQvcC6hQF"
            ) || [])[0];
            console.log(vm.priceedTicket);
            vm.TicketsResult = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
            vm.completeList = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
          }
          vm.TicketsResult = vm.completeList;
          vm.eventTicketResult = vm.TicketsResult;
          vm.dayChange("daylist");
          vm.dayChange("daylist1");
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
  },
  mounted() {
    this.fetchStatus();
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invaliduphone() {
      if (this.userContactPhone.length <= 0) {
        return true;
      }
      return (
        (this.userContactPhone || "").match(/\d/g) &&
        (this.userContactPhone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
  },
  metaInfo: {
    title: "MYLE - Grandiosity Events 9th annual celebrity PoloxJazz",
    meta: [
      {
        name: "description",
        content: "MYLE - Grandiosity Events 9th annual celebrity PoloxJazz",
      },

      {
        name: "keywords",
        content: "MYLE - Grandiosity Events 9th annual celebrity PoloxJazz",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/grandiosity-events-9th-annual-celebrity-poloxjazz",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}
</style>
